import { useStoreContext } from 'contexts';
import { ComponentProps, FunctionComponent } from 'react';

const spaceId = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;

/**
 * Used to display errors in preview mode. Outside of preview mode it renders empty.
 */
const Error = ({
  name,
  errors = [],
  type,
  id,
}: {
  name: string;
  errors: string[];
  type: string;
  id: string;
}) => {
  const { preview } = useStoreContext();

  const contentfulType = `Section - ${type
    .split(/(?=[A-Z])/)
    .slice(1)
    .map((word, i) => (i === 0 ? word : word.toLowerCase()))
    .join(' ')}`;

  console.warn(
    `Could not render [${contentfulType}] [${id}]. Invalid values for [${errors.join(', ')}]`
  );

  if (!preview) return <></>;

  const editLink = `https://app.contentful.com/spaces/${spaceId}/entries/${id}`;

  return (
    <section className="w-full bg-gray-300 p-4 py-8 text-[15px]">
      <div className="mx-auto flex max-w-screen-md flex-col gap-6 bg-white p-6 lg:p-10">
        <div className="flex flex-col gap-2">
          <p className="font-nimbus-sans-extd-d text-[20px] font-bold uppercase">
            Incomplete section
          </p>
        </div>
        <div>
          <p className="mb-2">
            This section will be hidden outside of preview mode. To enable this section, please
            provide correct values for the following fields:
          </p>
          <ul className="list-disc pl-6">
            {errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </div>
        <a href={editLink} target="_blank" className="button w-fit px-12" rel="noreferrer">
          Edit
        </a>
        <p className="flex flex-wrap gap-2 text-[12px] opacity-50">
          {type && (
            <span>
              <strong>Type:</strong> {contentfulType}
            </span>
          )}

          {id && (
            <span>
              <strong>Id:</strong> {id}
            </span>
          )}
          {name && (
            <span>
              <strong>Name:</strong> {name}
            </span>
          )}
        </p>
      </div>
    </section>
  );
};

const withPropsValidation =
  (Section: FunctionComponent, validate: (props: { validateProps: boolean }) => string[]) =>
  (props: ComponentProps<any>) => {
    const {
      data: {
        name,
        type,
        sys: { id },
      },
      validateProps = false,
    } = props;

    if (validateProps) {
      const errors = validate(props);
      if (errors.length > 0) return <Error id={id} name={name} type={type} errors={errors} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Section {...props} />;
  };

export default withPropsValidation;
