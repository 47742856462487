import { Fragment } from 'react';

const renderLines = text =>
  text?.split('<br>').map(line => (
    <Fragment key={line}>
      {line}
      <br />
    </Fragment>
  ));

export default renderLines;
