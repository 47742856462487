import { isArray } from 'underscore';

const validateRequired = values =>
  Object.entries(values).reduce((acc, [label, value]) => {
    const isEmptyArray = isArray(value) && value.length === 0;

    return [...acc, ...(!value || isEmptyArray ? [label] : [])];
  }, []);

export default validateRequired;
