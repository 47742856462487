import { SiteLink, Image as SiteImage, RichText } from 'components/shared';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { getLinkHref } from 'lib/utils/helpers';
import renderLines from 'components/shared/renderLines';
import { trackInWindow } from 'hooks/tracking/track';
import Title from 'components/title';
import withPropsValidation from './with-props-validation.tsx';
import validateRequired from './validate-required';

const richTextOptions = (richText, level = 1) => {
  const hyperlink = richText?.links?.entries?.hyperlink || [];
  const getLinkedEntry = linkId => hyperlink.find(l => l?.sys?.id === linkId);

  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children) => (
        <Title level={level} className="text-heading-1 mt-12">
          {children}
        </Title>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <Title level={level + 1} className="text-heading-2 mt-10">
          {children}
        </Title>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <Title level={level + 2} className="text-heading-3 mt-8">
          {children}
        </Title>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <Title level={level + 3} className="text-heading-4 mt-8">
          {children}
        </Title>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
      [INLINES.HYPERLINK]: ({ data: { uri } }, children) => (
        <SiteLink className="underline" external={uri}>
          {children}
        </SiteLink>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const entryLink = getLinkedEntry(node?.data?.target?.sys?.id);

        return entryLink ? (
          <SiteLink href={getLinkHref(entryLink)} className="underline">
            {children}
          </SiteLink>
        ) : (
          children
        );
      },
    },
  };
};

const Section = ({
  index: sectionIndex,
  data: { image, showScissors, title, titleType, subtitle, subtitleRichtext, link, showWhiteSpace },
}) => {
  return (
    <div className="relative w-full overflow-hidden antialiased">
      <div
        className={`flex flex-col items-center px-4 text-center text-blue ${
          showWhiteSpace ? 'my-32' : 'my-16'
        }`}
      >
        {image && (
          <SiteImage
            bgColor={false}
            src={image}
            layout="responsive"
            className="relative w-full max-w-[350px] object-cover md:mb-12"
          />
        )}
        {showScissors && (
          <img
            className={`block ${image ? 'mt-5' : ''}`}
            alt="Denham Scissors"
            loading="lazy"
            src="/icons/scissors-black.svg"
            width={94}
            height={43}
          />
        )}
        {title && (
          <Title
            level={sectionIndex === 0 ? 1 : 2}
            className={`${titleType === 'Display 1' ? 'text-display-1' : 'text-heading-1'} ${
              (image || showScissors) && 'md:mt-5'
            }`}
          >
            {renderLines(title)}
          </Title>
        )}
        {(subtitleRichtext || subtitle) && (
          <div
            className={` max-w-[80%] md:max-w-[550px] ${
              (title || image || showScissors) && 'mt-5'
            } ${link ? 'mb-2' : ''} ${
              subtitleRichtext ? '[&>*:first-child]:mt-0 [&>*:last-child]:mb-0 [&>*]:mb-4' : ''
            }`}
          >
            {subtitleRichtext ? (
              <RichText
                data={subtitleRichtext}
                options={richTextOptions(subtitleRichtext, sectionIndex === 0 ? 1 : 2)}
              />
            ) : (
              <p className="text-heading-6">{subtitle}</p>
            )}
          </div>
        )}
        {link && (
          <SiteLink
            className="text-button mt-5 underline"
            href={getLinkHref(link.link)}
            external={link.externalLink}
            onClick={() => trackInWindow({ event: 'Hero Clicked', title: link.text })}
          >
            {link.text}
          </SiteLink>
        )}
      </div>
    </div>
  );
};

export default withPropsValidation(
  Section,
  ({ data: { name, title, subtitle, subtitleRichtext } }) =>
    validateRequired({
      Name: name,
      'Title / Subtitle / Subtitle Richtext': [title, subtitle, subtitleRichtext].filter(Boolean),
    })
);
